import React from 'react';
import {Outlet, RouteObject} from 'react-router-dom';

export const widgetRoutes: RouteObject = {
  path: 'widgets',
  element: <Outlet />,
  children: [
    {
      path: 'g',
      children: [],
    },
  ],
};
