import React, {lazy} from 'react';
import {Outlet, RouteObject} from 'react-router-dom';
import NotificationProvider from '@local/frontend/providers/NotificationProvider';
import ModalProvider from '@local/frontend/libs/modals/ModalProvider';
import PostHogFeatureFlagsWrapper from '@local/frontend/providers/posthog/PostHogFeatureFlagsWrapper';
import PostHogGroupWrapper from '@local/frontend/providers/posthog/PostHogGroupWrapper';
import PostHogIdentifyWrapper from '@local/frontend/providers/PostHogIdentifyWrapper';
import MultiCardPerPageVariantWrapper from './per-page-variant/MultiCardPerPageVariantWrapper';

const MultiCardPageRoute = lazy(
  () =>
    import(
      /* webpackChunkName: "multi-card-page-route" */ './MultiCardPage.Route'
    )
);

export const multiCardRoutes: RouteObject = {
  path: 'm',
  element: (
    <NotificationProvider>
      <Outlet />
    </NotificationProvider>
  ),
  children: [
    {
      path: ':multiCardId',
      element: (
        <PostHogIdentifyWrapper>
          <PostHogGroupWrapper>
            <PostHogFeatureFlagsWrapper>
              <MultiCardPerPageVariantWrapper>
                <ModalProvider>
                  <MultiCardPageRoute />
                </ModalProvider>
              </MultiCardPerPageVariantWrapper>
            </PostHogFeatureFlagsWrapper>
          </PostHogGroupWrapper>
        </PostHogIdentifyWrapper>
      ),
    },
  ],
};
